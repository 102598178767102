<template>
  <v-app>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Vacation Allowance</h1>
        </div>
        <v-form ref="ppeRequest" v-model="valid">
          <v-row no-gutters>
            <v-col>
              <SelectField
                :items="vacationAllowanceType"
                v-model="formData.vacationAllowanceType"
                :label="'Vacation Allowance Type'"
                :itemText="'description'"
                :itemValue="'id'"
                :returnObject="true"
              />
            </v-col>
            <v-col :class="personnel.length > 0 ? 'mb-1' : 'mb-6'">
              <!-- <TextField v-model="formData.personnel" :label="'Personnel'" /> -->
              <v-btn
                class="btn-style justify-start text-style"
                @click.prevent="perSearch = true"
                width="100%"
                height="38"
                outlined
              >
                <v-icon left>mdi-magnify</v-icon> {{formData.personnel === null ? 'Personnnel': formData.personnel[0].name}}</v-btn
              >
            </v-col>
            <v-col>
              <SelectField
                :items="salaryPeriod"
                v-model="formData.salaryPeriod"
                :label="'Salary Cycle'"
                :itemText="'description'"
                :itemValue="'id'"
                :returnObject="true"
              />
            </v-col>

            <v-col>
                <SelectField
                  :items="VacationYears"
                  v-model="VacationYear"
                  :label="'Vacation Year'"
                  @onChange="vacationDayGeneration"
                />
            </v-col>
            <v-col>
              <TextField
                v-model="formData.vacationDays"
                :label="'Vacation Days'"
                :validate="false"
                :disable="true"
              />
            </v-col>
            <v-col>
              <TextField
                v-model="formData.vacationNotTakenDays"
                :label="'Vacation Not Taken Days'"
                :validate="false"
                :disable="true"
              />
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <v-row>
      <v-col cols="9 mt-5" class="d-flex justify-end">
        <Button
          :label="'Submit'"
          :btnType="'Submit'"
          @onClick="dialog = true"
          :disabled="!valid || (formData.vacationAllowanceType.id=== 2 && formData.vacationNotTakenDays===0) || (formData.vacationAllowanceType.id === 1 && formData.vacationDays < 21)"
          :isLoading="isSaving"
          class="mr-4"
        />

        <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
      </v-col>
    </v-row>
     <ConfirmationDialog :title="'Are you sure you want to submit?'" :dialog="dialog"  :isLoading="isSaving" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>

     <PersonnelSearch :dialog="perSearch" @selectPer="selectPersonnel"  @close="perSearch = false"/>
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import {
  employeeService,
  requestService,
  salaryPeriodService,
  salaryComponentService
} from '@/services'
import { CREATE_REQUEST } from '@/store/action-type'
import moment from 'moment'

export default {
  components: {
    TextField,
    PersonnelSearch,
    Button,
    SelectField,
    ConfirmationDialog
  },
  data () {
    return {
      dialog: false,
      creating: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      ItemType: null,
      perSearch: false,
      VacationYears: [],
      search: '',
      valid: false,
      isSaving: false,
      training: '',
      VacationYear: '',
      loadingPersonnelData: false,
      formData: {
        salaryPeriod: {},
        trainingTypeId: '',
        training: '',
        RequestTypeId: 6,
        startDate: '',
        resumptionDate: '',
        comments: '',
        isClaim: false,
        personnel: null,
        vacationDays: 0,
        vacationNotTakenDays: 0,
        vacationAllowanceType: ''
      },
      overTime: false,
      request: [],

      trainingType: [],

      personnelSearch: [],

      allowanceType: [],
      salaryPeriod: [],
      headers: [],
      selected: [],
      searchHeaders: [
        {
          text: 'FullName',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'email',
          value: 'email'
        }
      ],
      selectedPersonnelHeader: [
        {
          text: 'Name',
          value: 'name'
        },
        { text: 'Actions', value: 'actions' }
      ],
      requestHeader: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Training',
          value: 'training'
        },
        {
          text: 'Cost',
          value: 'amount'
        },
        {
          text: 'StartDate',
          value: 'startDate'
        },
        {
          text: 'EndDate',
          value: 'endDate'
        },
        {
          text: 'shortDescription',
          value: 'shortDescription'
        },
        { text: 'Comment', value: 'comments' },
        { text: 'Actions', value: 'actions' }
      ],
      personnel: [],
      selectedPersonnel: [],
      vacationAllowanceType: []
    }
  },
  computed: {
    endDate: function () {
      if (!this.formData.salaryPeriod.endDate) {
        return
      }
      return this.formData.salaryPeriod.endDate.split('T')[0]
    },
    startDate: function () {
      if (!this.formData.salaryPeriod.startDate) {
        return
      }
      return this.formData.salaryPeriod.startDate.split('T')[0]
    }
  },
  methods: {
    openPersonnelSearchModal () {
      this.$refs.personnelSearch.openModal()
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },

    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
    },
    personnelSearchFunction () {
      if (this.search === '') {
        this.personnelSearch = []
        return
      }
      this.loadingPersonnelData = true
      employeeService.searchPersonnel(this.search).then(result => {
        this.personnelSearch = result.data
      }).catch(() => {
        this.showAlertMessage('Failed to load Personnel', 'error')
      }).finally(() => {
        this.loadingPersonnelData = false
      })
    },
    comments (id, value, date) {
      // console.log(value)
      if (value !== '') {
        const newArray = this.selected.filter(comm => comm.date !== date)
        this.selected = newArray
        this.selected.push({
          employeeid: id,
          workHours: parseInt(value),
          date
        })
      } else {
        const newArray = this.selected.filter(comm => comm.date !== date)
        this.selected = newArray
      }
    },
    workHourInput (e) {
      if (e.keyCode === 189 || e.keyCode === 48) {
        e.preventDefault()
      }
    },
    selectPersonnel (item) {
      this.formData.personnel = [item]
    },
    removePersonnel (item) {
      let index = null
      for (let i = 0; i < this.selectedPersonnel.length; i++) {
        if (this.selectedPersonnel[i].id === item.id) {
          index = i
          break
        }
      }
      this.selectedPersonnel.splice(index, 1)
    },

    checkTimeSheetItem (item) {
      this.onReset()
      let id = this.formData.allowance.id
      salaryComponentService
        .getSalaryComponent(id)
        .then(result => {
          if (result.data.timesheetItemType === 1) {
            this.overTime = true
          } else {
            this.overTime = false
          }
        })
        .catch(() => {})
    },

    removeRequest (item) {
      let index = null
      for (let i = 0; i < this.request.length; i++) {
        if (this.request[i].id === item.id) {
          index = i
          break
        }
      }

      this.request.splice(index, 1)
    },
    generateRequest () {
      this.creating = true
      const allowanceRequester = []

      this.selectedPersonnel.forEach(per => {
        allowanceRequester.push({
          EmployeId: per.id,
          RequestTypeId: 1,
          RequestStartDate: this.startDate,
          salaryComponentId: this.formData.allowance.id,
          SalaryPeriodId: this.formData.salaryPeriod.id
        })
      })

      requestService
        .validateRequests(allowanceRequester)
        .then(result => {
          this.personnel = this.selectedPersonnel
          let existingDates = []
          if (result.data[0].existingDates.length !== 0) {
            existingDates = (result.data[0].existingDates)
          }
          if (this.formData.startDate === '') {
            this.headers = this.dateArray(
              this.startDate,
              this.endDate,
              existingDates
            )
          } else if (this.formData.startDate !== '' && this.formData.resumptionDate === '') {
            this.headers = this.dateArray(
              this.formData.startDate,
              this.endDate,
              existingDates
            )
          } else {
            this.headers = this.dateArray(
              this.formData.startDate,
              this.formData.resumptionDate,
              existingDates
            )
          }
        })
        .catch(() => {})
      this.creating = false
    },
    dateArray (startDate, endDate, existingDates) {
      let dateArr = []
      let prevDate = moment(startDate)._d

      let nextDate = moment(endDate)._d

      prevDate = moment(prevDate).format('MM-DD-YYYY')
      nextDate = moment(nextDate).format('MM-DD-YYYY')

      var start = new Date(prevDate)
      var end = new Date(nextDate)

      while (start <= end) {
        const val = existingDates.find(date => {
          return moment(date).format('YYYY-MM-DD') === moment(start).format('YYYY-MM-DD')
        })
        dateArr.push({
          date: moment(start).format('MMM Do'),
          day: moment(start).format('dddd'),
          value: moment(start).format('YYYY-MM-DD'),
          disable: () => {
            if (val) {
              return true
            }
            return false
          }
        })
        var newDate = start.setDate(start.getDate() + 1)
        start = new Date(newDate)
      }

      return dateArr
    },
    onSave () {
      // const salaryComponents = this.allowanceType.filter(all => all.isVacationAllowance === true)
      let data = [
        {
          Employeeid: this.formData.personnel[0].id,
          salaryPeriodId: this.formData.salaryPeriod.id,
          requestTypeId: 1,
          LeaveDays: 1,
          isClaim: this.formData.isClaim,
          // salaryComponentId: salaryComponents[0].id,
          salaryComponentId: this.formData.vacationAllowanceType.id,
          VacationYear: this.VacationYear,
          VacationDays: this.formData.vacationDays,
          VacationNotTakenDays: this.formData.vacationNotTakenDays
        }
      ]
      this.isSaving = true

      // console.log('Data', data)
      this.$store
        .dispatch(CREATE_REQUEST, { type: 'allowance', data })
        .then(() => {
          this.showAlertMessage('Vacation Allowance creation successful', 'success')
          this.isSaving = false
        })
        .catch(() => {
          this.showAlertMessage('Vacation Allowance creation failed', 'error')
        })
        .finally(() => {
          this.isSaving = false
          this.dialog = false
          this.onReset()
        })
    },
    vacationDayGeneration () {
      if (this.formData.personnel === null) return
      let data = {
        EmployeeId: this.formData.personnel[0].id,
        VacationYear: parseInt(this.VacationYear),
        'SalaryComponentId': this.formData.vacationAllowanceType.id
      }
      requestService.getVacationDays(data).then((res) => {
        let vacationDays = res.data.vacationDays
        let vacationNotTakenDays = res.data.vacationNotTakenDays
        if (res.data.hasError) {
          return this.showAlertMessage(res.data.validationMessage, 'error')
        }
        // let vacationSum = vacationDays + vacationNotTakenDays
        this.formData.vacationDays = vacationDays
        this.formData.vacationNotTakenDays = vacationNotTakenDays

        // if (this.formData.vacationAllowanceType.id === 1 && (vacationSum !== 21)) {
        //   return this.showAlertMessage('Personnel cannot apply for vacation', 'error')
        // }
        // console.log('i got here', this.formData.vacationAllowanceType)
        // if (this.formData.vacationAllowanceType.id === 2 && (vacationNotTakenDays === 0)) {
        //   return this.showAlertMessage('Personnel is not qualified for vacation not taken', 'error')
        // }
      }).catch(error => {
        // console.log(error)
      })
    },
    onReset () {
      this.formData.vacationAllowanceType = null
      this.formData.salaryPeriod = {}
      this.VacationYear = ''
      this.formData.vacationNotTakenDays = 0
      this.formData.vacationDays = 0
      this.selectedPersonnel = []
      this.formData.personnel = null
      this.formData.vacationAllowanceType = ''
      this.search = ''
    }
    // getAYearBackward () {
    //   this.VacationYears = [moment().subtract(1, 'year').format('YYYY'), moment().format('YYYY')]
    // }
  },
  mounted () {
    salaryComponentService
      .getAllSalaryAllowance()
      .then(result => {
        this.allowanceType = result.data
      })
      .catch(() => {})
    salaryComponentService
      .getVacationAllowance()
      .then(result => {
        this.vacationAllowanceType = result.data
      })
      .catch(() => {})
    requestService
      .getTrainingType()
      .then(result => {
        this.trainingType = result.data
      })
      .catch(() => {})

    salaryPeriodService
      .getAllSalaryPeriod()
      .then(result => {
        this.salaryPeriod = result.data
      })
      .catch(() => {})

    salaryPeriodService
      .getSalaryYears()
      .then(result => {
        this.VacationYears = result.data
        this.VacationYears.forEach((dat, index) => {
          this.VacationYears[index] = parseInt(this.VacationYears[index])
        })
      })
      .catch(() => {})

    // this.getAYearBackward()
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.btn-style {
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
.text-style {
  text-transform: capitalize;
}
.input {
  border: 1px solid grey;
  height: 30px;
  padding-left: 5px;
  width: 90px;
}
.input:disabled {
  background: #a0a0a0;
}
.comment {
  border: 1px solid grey;
  height: 30px;
  padding-left: 5px;
  width: 190px;
}
.cal-day {
  font-size: 13px;
  margin: 0 !important;
  font-weight: 100;
  width: 75px;
}
.cal-date {
  font-size: 15px;
  margin: 0 !important;
}
</style>
